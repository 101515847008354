<script>
  import NavBar from "@/components/nav-bar";
  import RightBar from "@/components/right-bar";
  import { notificationMethods } from "@/state/helpers";
  import Footer from "@/components/footer";
  import { callUrl, getUserData } from '@/helpers/api-apolo';
  import * as helpers from '@/helpers/helper-functions';

  export default {
    data() {
      return {
        openedTasks: 0,
        userData: {}
      }
    },
    computed: {
      getRefreshTaskCount() {
        return this.$store ? this.$store.state.notification.refreshTaskCount : false;
      }
    },
    watch: {
      $route: {
        handler: "onRoutechange",
        immediate: true,
        deep: true,
      },
      getRefreshTaskCount(val) {
        if (val && val.status) {
          this.getOpenedTasks();
        }
      }
    },
    methods: {
      ...helpers,
      ...notificationMethods,
      onRoutechange(ele) {
        this.initActiveMenu(ele.path);
      },
      initActiveMenu(ele) {
        setTimeout(() => {
          if (document.querySelector("#navbar-nav")) {
            let a = document
              .querySelector("#navbar-nav")
              .querySelector('[href="' + ele + '"]');

            if (a) {
              a.classList.add("active");
              let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
              if (parentCollapseDiv) {
                parentCollapseDiv.classList.add("show");
                parentCollapseDiv.parentElement.children[0].classList.add(
                  "active"
                );
                parentCollapseDiv.parentElement.children[0].setAttribute(
                  "aria-expanded",
                  "true"
                );
                if (
                  parentCollapseDiv.parentElement.closest(
                    ".collapse.menu-dropdown"
                  )
                ) {
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .classList.add("show");
                  if (
                    parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                  )
                    parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
                }
              }
            }
          }
        }, 1000);
      },
      async getOpenedTasks() {
        try {
          this.setRefreshTaskCount({status: false});
          this.openedTasks = 0;
          const params     = [`status=Aberto`];
          const dataM      = await callUrl({}, `/user/${this.userData.user_id}/task?${params.join('&')}`, 'get');
          if (dataM && dataM.length) {
            this.openedTasks = dataM.length;
          }
        } catch (error) {
          console.error(error.message);
        }
      }
    },
    mounted() {
      this.userData = getUserData();
      this.getOpenedTasks();
      this.intervalTasks = setInterval(async () => {
        await this.getOpenedTasks()
      }, 60000);
    },
    beforeUnmount () {
      clearInterval(this.intervalTasks);
    },
    components: {
      NavBar,
      RightBar,
      Footer
    }
  };
</script>

<template>
  <div>
    <div id="layout-wrapper">
      <NavBar />
      <!-- ========== App Menu ========== -->
      <div class="app-menu navbar-menu">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <!-- Dark Logo-->
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt="" height="70" style="margin-top:15px;" />
            </span>
          </router-link>
          <!-- Light Logo-->
          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt="" height="70" style="margin-top:15px;" />
            </span>
          </router-link>
          <button type="button" class="
              btn btn-sm
              p-0
              fs-20
              header-item
              float-end
              btn-vertical-sm-hover
            " id="vertical-hover">
            <i class="ri-record-circle-line"></i>
          </button>
        </div>
        <div id="scrollbar">
          <div class="container-fluid">
            <ul class="navbar-nav h-100" id="navbar-nav">
              <!--li class="menu-title">
                <span data-key="t-menu"> {{ $t("t-menu") }}</span>
              </li>
              <li class="nav-item">
                <a class="nav-link menu-link" href="#sidebarApps" data-bs-toggle="collapse" role="button"
                  aria-expanded="false" aria-controls="sidebarApps">
                  <i class="ri-apps-2-line"></i>
                  <span data-key="t-apps"> Menu</span>
                </a>
                <div class="collapse menu-dropdown" id="sidebarApps">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link :to="{ name: 'task-list' }" class="nav-link" data-key="t-tasks">
                        <i class="mdi mdi-calendar-multiple-check"></i>
                        <span data-key="t-tasks">{{ $t("t-tasks") }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/apps/crm-leads" class="nav-link" data-key="t-leads">
                        <i class="mdi mdi-crosshairs-gps"></i>
                        <span data-key="t-leads">{{ $t("t-leads") }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/apps/crm-deals" class="nav-link" data-key="t-deals">
                        <i class="bx bx-money-withdraw"></i>
                        <span data-key="t-deals">{{ $t("t-deals") }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link :to="{ name: 'people-list' }" class="nav-link" data-key="t-people">
                        <i class="mdi mdi-account-group"></i>
                        <span data-key="t-people">{{ $t("t-people") }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/chat" class="nav-link" data-key="t-chat">
                        <i class="mdi mdi-chat-processing-outline"></i>
                        <span data-key="t-chat">{{ $t("t-chat") }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/apps/crm-products" class="nav-link" data-key="t-products">
                        <i class="mdi mdi-cube-outline"></i>
                        <span data-key="t-products">{{ $t("t-products") }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/users" class="nav-link" data-key="t-users">
                        <i class="mdi mdi-focus-field-horizontal"></i>
                        <span data-key="t-conf">Usuários</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li-->
              <li class="nav-item">
                <router-link :to="{ name: 'task-list' }" class="nav-link position-relative" data-key="t-tasks">
                  <i class="mdi mdi-calendar-multiple-check"></i>
                  <span data-key="t-tasks">{{ $t("t-tasks") }}<span v-if="openedTasks > 0" class="counter position-absolute top-0 end-0 badge rounded-pill bg-danger">{{ openedTasks }}</span></span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/apps/crm-leads" class="nav-link" data-key="t-leads">
                  <i class="mdi mdi-crosshairs-gps"></i>
                  <span data-key="t-leads">{{ $t("t-leads") }}</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/apps/crm-deals" class="nav-link" data-key="t-deals">
                  <i class="bx bx-money-withdraw"></i>
                  <span data-key="t-deals">{{ $t("t-deals") }}</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'people-list' }" class="nav-link" data-key="t-people">
                  <i class="mdi mdi-account-group"></i>
                  <span data-key="t-people">{{ $t("t-people") }}</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/chat" class="nav-link" data-key="t-chat">
                  <i class="mdi mdi-chat-processing-outline"></i>
                  <span data-key="t-chat">{{ $t("t-chat") }}</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/apps/crm-products" class="nav-link" data-key="t-products">
                  <i class="mdi mdi-cube-outline"></i>
                  <span data-key="t-products">{{ $t("t-products") }}</span>
                </router-link>
              </li>

              <li class="nav-item">
                <a class="nav-link menu-link" href="#sidebarSettings" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarSettings">
                  <i class="ri-apps-2-line"></i>
                  <span data-key="t-apps">{{ $t("t-settings") }}</span>
                </a>
                <div class="collapse menu-dropdown" id="sidebarSettings">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/apps/crm-pipelines" class="nav-link" data-key="t-pipeline">
                        <i class="bx bx-filter"></i>
                        <span data-key="t-pipeline">{{ $t("t-pipeline") }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link :to="{ name: 'hook-list' }" class="nav-link" data-key="t-integration">
                        <i class="mdi mdi-power-plug-outline"></i>
                        <span data-key="t-integration">{{ $t("t-integration") }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/users" class="nav-link" data-key="t-users">
                        <i class="mdi mdi-focus-field-horizontal"></i>
                        <span data-key="t-conf">{{ $t("t-users") }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/invoices/list" class="nav-link" data-key="t-crm">
                        <i class="mdi mdi-layers-triple"></i>
                        <span data-key="t-dashboards">{{ $t("t-custom-fields") }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item" v-if="userData?.profile === 'master'">
                      <router-link :to="{ name: 'integration-token-list' }" class="nav-link" data-key="t-integration-token">
                        <i class="mdi mdi-fingerprint"></i>
                        <span data-key="t-integration-token">{{ $t("t-integration-token") }}</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>

              <li class="nav-item">
                <a class="nav-link menu-link" href="#sidebarFFID" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarFFID">
                  <i class="ri-apps-2-line"></i>
                  <span data-key="t-apps">FFID</span>
                </a>
                <div class="collapse menu-dropdown" id="sidebarFFID">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <a href="https://app.ffid.com.br/#/integratedflow" class="nav-link" data-key="t-automations">
                        <i class="mdi mdi-robot-love-outline"></i>
                        <span data-key="t-automation">{{ $t("t-automations") }}</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="https://app.ffid.com.br/#/menuengagement" class="nav-link" data-key="t-campaigns">
                        <i class="mdi mdi-bullhorn-outline"></i>
                        <span data-key="t-campaigns">{{ $t("t-campaigns") }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <!-- Sidebar -->
        </div>
        <!-- Left Sidebar End -->
        <!-- Vertical Overlay-->
        <div class="vertical-overlay"></div>
      </div>
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->

      <div class="main-content">
        <div class="page-content">
          <!-- Start Content-->
          <div class="container-fluid">
            <slot />
          </div>
        </div>
        <Footer />
      </div>
      <RightBar />
    </div>
  </div>
</template>
