<script>
  import { SimpleBar } from "simplebar-vue3";
  import { layoutComputed, notificationMethods } from "@/state/helpers";
  import { callUrl, getUserData } from '@/helpers/api-apolo';
  import * as helpers from '@/helpers/helper-functions';

  export default {
    components: {
      SimpleBar
    },
    data() {
      return {
        openedTasks: 0,
        userData: {},
        settings: {
          minScrollbarLength: 60,
        },
      };
    },
    computed: {
      ...layoutComputed,
      layoutType: {
        get() {
          return this.$store ? this.$store.state.layout.layoutType : {} || {};
        },
      },
      getRefreshTaskCount() {
        return this.$store ? this.$store.state.notification.refreshTaskCount : false;
      }
    },
    watch: {
      $route: {
        handler: "onRoutechange",
        immediate: true,
        deep: true,
      },
      getRefreshTaskCount(val) {
        if (val && val.status) {
          this.getOpenedTasks();
        }
      }
    },
    mounted() {
      this.userData = getUserData();
      this.getOpenedTasks();
      this.intervalTasks = setInterval(async () => {
        await this.getOpenedTasks()
      }, 60000);
      if (document.querySelectorAll(".navbar-nav .collapse")) {
        let collapses = document.querySelectorAll(".navbar-nav .collapse");
        collapses.forEach((collapse) => {
          // Hide sibling collapses on `show.bs.collapse`
          collapse.addEventListener("show.bs.collapse", (e) => {
            e.stopPropagation();
            let closestCollapse = collapse.parentElement.closest(".collapse");
            if (closestCollapse) {
              let siblingCollapses =
                closestCollapse.querySelectorAll(".collapse");
              siblingCollapses.forEach((siblingCollapse) => {
                if (siblingCollapse.classList.contains("show")) {
                  let aChild = siblingCollapse.parentNode.firstChild;
                  if (aChild.hasAttribute("aria-expanded")){
                    aChild.setAttribute("aria-expanded", "false");
                  }
                  siblingCollapse.classList.remove("show");
                }
              });
            } else {
              let getSiblings = (elem) => {
                // Setup siblings array and get the first sibling
                let siblings = [];
                let sibling = elem.parentNode.firstChild;
                // Loop through each sibling and push to the array
                while (sibling) {
                  if (sibling.nodeType === 1 && sibling !== elem) {
                    siblings.push(sibling);
                  }
                  sibling = sibling.nextSibling;
                }
                return siblings;
              };
              let siblings = getSiblings(collapse.parentElement);
              siblings.forEach((item) => {
                if (item.childNodes.length > 2)
                  item.firstElementChild.setAttribute("aria-expanded", "false");
                let ids = item.querySelectorAll("*[id]");
                ids.forEach((item1) => {
                  let aChild = item1.parentNode.firstChild;
                  if (aChild.hasAttribute("aria-expanded")){
                    aChild.setAttribute("aria-expanded", "false");
                  }
                  item1.classList.remove("show");
                  if (item1.childNodes.length > 2) {
                    let val = item1.querySelectorAll("ul li a");

                    val.forEach((subitem) => {
                      if (subitem.hasAttribute("aria-expanded"))
                        subitem.setAttribute("aria-expanded", "false");
                    });
                  }
                });
              });
            }
          });

          // Hide nested collapses on `hide.bs.collapse`
          collapse.addEventListener("hide.bs.collapse", (e) => {
            e.stopPropagation();
            let childCollapses = collapse.querySelectorAll(".collapse");
            childCollapses.forEach((childCollapse) => {
              let childCollapseInstance = childCollapse;
              childCollapseInstance.hide();
            });
          });
        });
      }
    },
    beforeUnmount () {
      clearInterval(this.intervalTasks);
    },
    methods: {
      ...helpers,
      ...notificationMethods,
      onRoutechange(ele) {
        this.initActiveMenu(ele.path);
        if (document.getElementsByClassName("mm-active").length > 0) {
          const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
          if (currentPosition > 500)
            if (this.$refs.isSimplebar)
              this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
        }
      },
      initActiveMenu(ele) {
        setTimeout(() => {
          if (document.querySelector("#navbar-nav")) {
            let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]');

            if (a) {
              a.classList.add("active");
              let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
              if (parentCollapseDiv) {
                parentCollapseDiv.classList.add("show");
                parentCollapseDiv.parentElement.children[0].classList.add("active");
                parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
                if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                  parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                  if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                    parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add(
                      "active");
                }
              }
            }
          }
        }, 0);
      },
      async getOpenedTasks() {
        try {
          this.setRefreshTaskCount({status: false})
          this.openedTasks = 0;
          const params     = [`status=Aberto`];
          const dataM      = await callUrl({}, `/user/${this.userData.user_id}/task?${params.join('&')}`, 'get');
          if (dataM && dataM.length) {
            this.openedTasks = dataM.length;
          }
        } catch (error) {
          console.error(error.message);
        }
      }
    }
  };
</script>

<template>
  <div class="container-fluid">
    <div id="two-column-menu"></div>

    <template v-if="layoutType === 'twocolumn'">
      <SimpleBar class="navbar-nav" id="navbar-nav">
        <li class="menu-title">
          <span data-key="t-menu"> {{ $t("t-menu") }}</span>
        </li>
      </SimpleBar>
    </template>

    <template v-else>
      <ul class="navbar-nav h-100" id="navbar-nav">
        <li class="menu-title">
          <span data-key="t-menu"> {{ $t("t-menu") }}</span>
        </li>
        <li class="nav-item">
          <router-link to="/apps/crm-leads" class="nav-link" data-key="t-leads">
            <div data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('t-leads')">
              <i class="mdi mdi-crosshairs-gps"></i>
              <span data-key="t-leads">{{ $t("t-leads") }}</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/apps/crm-deals" class="nav-link" data-key="t-deals">
            <div data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('t-deals')">
              <i class="bx bx-money-withdraw"></i>
              <span data-key="t-deals">{{ $t("t-deals") }}</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'people-list' }" class="nav-link" data-key="t-people">
            <div data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('t-people')" class="position-relative">
              <i class="mdi mdi-account-group"></i>
              <span data-key="t-people">{{ $t("t-people") }}</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'task-list' }" class="nav-link" data-key="t-tasks">
            <div data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('t-tasks')">
              <i class="mdi mdi-calendar-multiple-check"><span v-if="openedTasks > 0" class="counter position-absolute top-0 ms-2 badge rounded-pill bg-danger">{{ openedTasks }}</span></i>
              <span data-key="t-tasks">{{ $t("t-tasks") }} <span v-if="openedTasks > 0" class="position-absolute top-0 ms-1 badge rounded-pill bg-danger">{{ openedTasks }}</span></span>
            </div>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/chat" class="nav-link" data-key="t-chat">
            <div data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('t-chat')">
              <i class="mdi mdi-chat-processing-outline"></i>
              <span data-key="t-chat">{{ $t("t-chat") }}</span>
            </div>
          </router-link>
        </li>
        <!--
        <li class="nav-item">
          <router-link to="/mailbox" class="nav-link" data-key="t-mailbox">
          <router-link to="/" class="nav-link" data-key="t-mailbox">
            <i class="mdi mdi-email-outline"></i>
            <span data-key="t-mailbox">{{ $t("t-mailbox") }} - <small>Em Breve</small></span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/" class="nav-link" data-key="t-proposal">
            <i class="mdi mdi-rocket-launch-outline"></i>
            <span data-key="t-proposal">Propostas - <small>Em Breve</small></span>
          </router-link>
        </li>
      -->
        <li class="nav-item">
          <router-link to="/apps/crm-products" class="nav-link" data-key="t-products">
            <div data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('t-products')">
              <i class="mdi mdi-cube-outline"></i>
              <span data-key="t-products">{{ $t("t-products") }}</span>
            </div>
          </router-link>
        </li>
        <li class="menu-title"><i class="ri-more-fill"></i><span data-key="t-pages">{{ $t("t-settings") }}</span></li>
        <li class="nav-item">
          <router-link to="/apps/crm-pipelines" class="nav-link" data-key="t-pipeline">
            <div data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('t-pipeline')">
              <i class="mdi mdi-cog"></i>
              <span data-key="t-pipeline">Empresa</span>
            </div>
          </router-link>
        </li>
        <!--
        <li class="nav-item">
          <router-link to="/apps/crm-pipelines" class="nav-link" data-key="t-pipeline">
            <div data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('t-pipeline')">
              <i class="bx bx-filter"></i>
              <span data-key="t-pipeline">{{ $t("t-pipeline") }}</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'hook-list' }" class="nav-link" data-key="t-integration">
            <div data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('t-integration')">
              <i class="mdi mdi-power-plug-outline"></i>
              <span data-key="t-integration">{{ $t("t-integration") }}</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/users" class="nav-link" data-key="t-users">
            <div data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('t-users')">
              <i class="ri-user-add-line"></i>
              <span data-key="t-users">{{ $t("t-users") }}</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/invoices/list" class="nav-link" data-key="t-crm">
            <div data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('t-custom-fields')">
              <i class="mdi mdi-layers-triple"></i>
              <span data-key="t-custom-fields">{{ $t("t-custom-fields") }}</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item" v-if="userData?.profile === 'master'">
          <router-link :to="{ name: 'integration-token-list' }" class="nav-link" data-key="t-integration-token">
            <div data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('t-integration-token')">
              <i class="mdi mdi-fingerprint"></i>
              <span data-key="t-integration-token">{{ $t("t-integration-token") }}</span>
            </div>
          </router-link>
        </li>
        -->
        <li class="menu-title"><i class="ri-more-fill"></i><span data-key="t-pages">FFID</span></li>
        <li class="nav-item">
          <div data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('t-automations')">
            <a href="https://app.ffid.com.br/#/integratedflow" class="nav-link" data-key="t-automations">
              <i class="mdi mdi-robot-love-outline"></i>
              <span data-key="t-automation">{{ $t("t-automations") }}</span>
            </a>
          </div>
        </li>
        <li class="nav-item">
          <div data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('t-campaigns')">
            <a href="https://app.ffid.com.br/#/menuengagement" class="nav-link" data-key="t-campaigns">
              <i class="mdi mdi-bullhorn-outline"></i>
              <span data-key="t-campaigns">{{ $t("t-campaigns") }}</span>
            </a>
          </div>
        </li>
      </ul>
    </template>
  </div>
  <!-- Sidebar -->
</template>
