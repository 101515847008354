import { Settings, DateTime } from "luxon";

Settings.defaultLocale = "pt-br";

export const getTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function translateDateOnly (value) {
    return DateTime.fromISO(value).setZone(getTimezone()).toFormat('dd/MM/yyyy');
}

export function translateTimeOnly (value) {
    return DateTime.fromISO(value).setZone(getTimezone()).toFormat('HH:mm:ss');
}

export function limitString (string = '', limit = 0) {  
    const truncated = string.substring(0, limit)
    return truncated + (truncated.length < string.length ? '...' : '');
}

export function generateId () {
    var timestamp = (new Date().getTime() / 1000 | 0).toString(16)
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
      return (Math.random() * 16 | 0).toString(16)
    }).toLowerCase()
}

export function getDiffDateFriendly (date) {
    return DateTime.fromISO(date).toRelative()
}

export function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}