<script>
import { layoutComputed ,layoutMethods } from "@/state/helpers";

import Vertical from "./vertical";
import Horizontal from "./horizontal";
import TwoColumns from "./twocolumn";

export default {
    components: {
        Vertical,
        Horizontal,
        TwoColumns
    },
    data() {
        return {};
    },
    methods: {
      ...layoutMethods,
    },
    computed: {
      ...layoutComputed,
      isMobile() {
        return screen.width <= 760;
      }
    },
    mounted() {
      if (this.isMobile) {
        this.changeSidebarSize({
          sidebarSize: 'lg'
        });
      }
      let layoutType = localStorage.getItem('layoutType');
      let type = ''
      if (layoutType && layoutType !== '') {
        type = layoutType
      } else {
        type = 'vertical'
        localStorage.setItem('layoutType', type)
        localStorage.setItem("rightbar_isopen", false);
        localStorage.setItem('sidebarColor', 'dark');
        localStorage.setItem('mode', 'light');
        localStorage.setItem('hoverd', 'false');
        localStorage.setItem('topbar', 'light');
        localStorage.setItem('layoutWidth', 'fluid');
        // localStorage.setItem('layoutType', 'vertical');
        localStorage.setItem('sidebarSize', 'lg');
        localStorage.setItem('sidebarView', 'default');
        localStorage.setItem('position', 'fixed');
      }
      this.changeLayoutType({
        layoutType: type,
      });
    }
};
</script>

<template>
<div>
    <div class="loading-mobile" style="background-color: black; opacity:0.8" v-show="showOverlay">
      <img class="overlay-apolo-mobile" style="max-height: 600px !important; max-width: 800px !important;" src="@/assets/images/logo_animated_dark.gif" alt="">
    </div>
    <Vertical v-if="layoutType === 'vertical'" :layout="layoutType">
        <slot />
    </Vertical>

    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
        <slot />
    </Horizontal>

    <TwoColumns v-if="layoutType === 'twocolumn'" :layout="layoutType">
        <slot />
    </TwoColumns>
</div>
</template>
<style scoped>
  .loading-mobile {
    position: fixed;
    width: 100% !important;
    height: 100vh !important;
    z-index: 999999999 !important;
    background-color: black;
    opacity: 0.8;
  }
  .loading-default {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    z-index: 999999999 !important;
  }
  .overlay-apolo-mobile {
    position: absolute;
    width: 100% !important;
    opacity: 0.8 !important;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
  }
  .overlay-apolo-default {
    width: 100% !important;
    opacity: 0.8 !important;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
  }
</style>
