<script>
  import i18n from "../i18n";
  import { logOut, getUserData, callUrl } from '@/helpers/api-apolo';
  import { layoutMethods, layoutComputed } from "@/state/helpers";
  import { saError } from '@/helpers/global-functions';
  import { SimpleBar } from "simplebar-vue3";
  import * as helpers from '@/helpers/helper-functions';
  import Swal from "sweetalert2";
  import { DateTime } from 'luxon';

  export default {
    data() {
      return {
        user: {},
        users: [],
        languages: [
          {
            flag: require("@/assets/images/flags/brazil.svg"),
            language: "br",
            title: "Português",
          },
          {
            flag: require("@/assets/images/flags/us.svg"),
            language: "en",
            title: "English",
          },
          {
            flag: require("@/assets/images/flags/french.svg"),
            language: "fr",
            title: "French",
          },
          {
            flag: require("@/assets/images/flags/spain.svg"),
            language: "sp",
            title: "Spanish",
          },
          {
            flag: require("@/assets/images/flags/china.svg"),
            language: "ch",
            title: "Chinese",
          },
          {
            flag: require("@/assets/images/flags/germany.svg"),
            language: "gr",
            title: "Deutsche",
          },
          {
            flag: require("@/assets/images/flags/russia.svg"),
            language: "ru",
            title: "русский",
          },
        ],
        lan: i18n.locale,
        text: null,
        flag: null,
        value: null,
        myVar: 1,
        alerts: []
      };
    },
    components: {
      SimpleBar
    },
    methods: {
      ...layoutMethods,
      ...helpers,
      logout() {
        logOut()
        this.$router.push({ path: '/login' });
      },
      isCustomDropdown() {
        //Search bar
        // var searchOptions = document.getElementById("search-close-options");
        // var dropdown = document.getElementById("search-dropdown");
        // var searchInput = document.getElementById("search-options");
        //
        // searchInput.addEventListener("focus", () => {
        //   var inputLength = searchInput.value.length;
        //   if (inputLength > 0) {
        //     dropdown.classList.add("show");
        //     searchOptions.classList.remove("d-none");
        //   } else {
        //     dropdown.classList.remove("show");
        //     searchOptions.classList.add("d-none");
        //   }
        // });
        //
        // searchInput.addEventListener("keyup", () => {
        //   var inputLength = searchInput.value.length;
        //   if (inputLength > 0) {
        //     dropdown.classList.add("show");
        //     searchOptions.classList.remove("d-none");
        //   } else {
        //     dropdown.classList.remove("show");
        //     searchOptions.classList.add("d-none");
        //   }
        // });
        //
        // searchOptions.addEventListener("click", () => {
        //   searchInput.value = "";
        //   dropdown.classList.remove("show");
        //   searchOptions.classList.add("d-none");
        // });
        //
        // document.body.addEventListener("click", (e) => {
        //   if (e.target.getAttribute("id") !== "search-options") {
        //     dropdown.classList.remove("show");
        //     searchOptions.classList.add("d-none");
        //   }
        // });
      },
      toggleHamburgerMenu() {
        var windowSize = document.documentElement.clientWidth;

        if (windowSize > 767)
          document.querySelector(".hamburger-icon").classList.toggle("open");

        //For collapse horizontal menu
        if (
          document.documentElement.getAttribute("data-layout") === "horizontal"
        ) {
          document.body.classList.contains("menu") ?
            document.body.classList.remove("menu") :
            document.body.classList.add("menu");
        }

        //For collapse vertical menu
        if (document.documentElement.getAttribute("data-layout") === "vertical") {
          if (windowSize < 1025 && windowSize > 767) {
            document.body.classList.remove("vertical-sidebar-enable");
            document.documentElement.getAttribute("data-sidebar-size") == "sm" ?
              document.documentElement.setAttribute("data-sidebar-size", "") :
              document.documentElement.setAttribute("data-sidebar-size", "sm");
          } else if (windowSize > 1025) {
            document.body.classList.remove("vertical-sidebar-enable");
            document.documentElement.getAttribute("data-sidebar-size") == "lg" ?
              document.documentElement.setAttribute("data-sidebar-size", "sm") :
              document.documentElement.setAttribute("data-sidebar-size", "lg");
          } else if (windowSize <= 767) {
            document.body.classList.add("vertical-sidebar-enable");
            document.documentElement.setAttribute("data-sidebar-size", "lg");
          }
        }

        //Two column menu
        if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
          document.body.classList.contains("twocolumn-panel") ?
            document.body.classList.remove("twocolumn-panel") :
            document.body.classList.add("twocolumn-panel");
        }
      },
      toggleMenu() {
        this.$parent.toggleMenu();
      },
      toggleRightSidebar() {
        this.$parent.toggleRightSidebar();
      },
      initFullScreen() {
        document.body.classList.toggle("fullscreen-enable");
        if (
          !document.fullscreenElement &&
          /* alternative standard method */
          !document.mozFullScreenElement &&
          !document.webkitFullscreenElement
        ) {
          // current working methods
          if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
          } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen(
              Element.ALLOW_KEYBOARD_INPUT
            );
          }
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          }
        }
      },
      setLanguage(locale, country, flag) {
        console.log(locale, country, flag)
        this.lan = locale;
        this.text = country;
        this.flag = flag;
        document.getElementById("header-lang-img").setAttribute("src", flag);
        i18n.global.locale = locale;
      },
      toggleDarkMode() {
        if (this.mode === 'light') {
          this.changeMode({mode: 'dark'})
        } else {
          this.changeMode({mode: 'light'})
        }
      },
      async alertMarkAsRead(alert) {
        try {
          await callUrl({}, `/alert/${alert.id}/read`, 'put')
          Swal.fire("Sucesso!", "Alerta marcado como lido", "success");
          this.getAlerts()
        } catch (error) {
          saError(error.message);
        }
      },
      async getAlerts() {
        try {
          const date      = DateTime.now().setZone(helpers.getTimezone());
          const dateBegin = date.minus({ days: 7 }).toFormat('yyyy-MM-dd') + 'T00:00:00';
          const dateEnd   = date.plus({ days: 7 }).toFormat('yyyy-MM-dd') + 'T23:59:59';
          this.alerts  = [];
          const params = [`read=false`, `expires_begin=${dateBegin}`, `expires_end=${dateEnd}`];
          const dataM  = await callUrl({}, '/alert?' + params.join('&'), 'get');
          if (dataM && dataM.length) {
            this.alerts = dataM;
          }
        } catch (error) {
          console.log(error.message);
        }
      }
    },
    computed: {
      ...layoutComputed,
    },
    async mounted() {
      this.getAlerts();
      this.user = getUserData();
      if (!this.user.avatar) {
        this.user.avatar = require('@/assets/images/users/user-dummy-img.jpg');
      }
      document.addEventListener("scroll", function () {
        var pageTopbar = document.getElementById("page-topbar");
        if (pageTopbar) {
          document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50 ? pageTopbar.classList.add(
              "topbar-shadow") : pageTopbar.classList.remove("topbar-shadow");
        }
      });
      if (document.getElementById("topnav-hamburger-icon"))
        document
            .getElementById("topnav-hamburger-icon")
            .addEventListener("click", this.toggleHamburgerMenu);

      this.isCustomDropdown();
    },
  };
</script>

<style scoped>
.mark-as-read-box {
  position: absolute;
  bottom: 7px;
  right: 7px;
}
</style>

<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-dark.png" alt="" height="50" />
              </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-light.png" alt="" height="70" style="margin-top:15px;" />
              </span>
            </router-link>
          </div>

          <button type="button" class="
              btn btn-sm
              px-3
              fs-16
              header-item
              vertical-menu-btn
              topnav-hamburger
            " id="topnav-hamburger-icon">
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
        </div>

        <div class="d-flex align-items-center">
          <div class="dropdown d-md-none topbar-head-dropdown header-item">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="bx bx-search fs-22"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-search-dropdown">
              <form class="p-3">
                <div class="form-group m-0">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search ..."
                      aria-label="Recipient's username" />
                    <button class="btn btn-primary" type="submit">
                      <i class="mdi mdi-magnify"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="ms-1 header-item d-none d-sm-flex">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              data-toggle="fullscreen" @click="initFullScreen">
              <i class="bx bx-fullscreen fs-22"></i>
            </button>
          </div>
          <div class="ms-1 header-item d-none d-sm-flex">
            <div class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas" id="mdi-cog">
              <i class="mdi mdi-cog-outline fs-22"></i>
            </div>
          </div>
          <div class="ms-1 header-item d-none d-sm-flex">
            <button type="button" class=" btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
              @click="toggleDarkMode">
              <i class="bx bx-moon fs-22"></i>
            </button>
          </div>

          <div class="dropdown topbar-head-dropdown ms-1 header-item">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <i class="bx bx-bell fs-22"></i>
              <span v-if="alerts.length > 0" class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                {{alerts.length}}<span class="visually-hidden">Alertas não lidos</span>
              </span>
            </button>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">
              <div class="dropdown-head bg-primary bg-pattern rounded-top">
                <div class="p-3">
                  <div class="row align-items-center">
                    <div class="col">
                      <h6 class="m-0 fs-16 fw-semibold text-white">
                        Notificações
                      </h6>
                    </div>
                    <div class="col-auto dropdown-tabs">
                      <span class="badge badge-soft-light fs-13"> {{alerts.length}} Novas</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-content" id="notificationItemsTabContent">
                <SimpleBar data-simplebar style="max-height: 300px" class="pe-2">
                  <div v-for="(alert, index) of alerts" :key="index" class="text-reset notification-item d-block dropdown-item position-relative">
                    <div class="d-flex">
                      <div class="avatar-xs me-3">
                        <span class="avatar-title bg-soft-info text-info rounded-circle fs-16">
                          <i class="bx bx-task"></i>
                        </span>
                      </div>
                      <button type="button" title="marcar como lido" class="mark-as-read-box btn btn-info btn-sm" @click.prevent="alertMarkAsRead(alert)">Lido</button>
                      <div class="flex-1">
                        <router-link :to="{ name: 'task-list' }">
                          <h6 class="mt-0 mb-2 lh-base">{{ alert.title }}</h6>
                        </router-link>
                        <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                          <span><i class="mdi mdi-clock-outline"></i> {{translateDateOnly(alert.expires_at)}} <small class="text-muted">{{translateTimeOnly(alert.expires_at)}}</small></span>
                        </p>
                      </div>
                    </div>
                  </div>
                </SimpleBar>
              </div>
            </div>
          </div>

          <div class="dropdown ms-sm-3 header-item topbar-user">
            <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <span class="d-flex align-items-center">
                <img class="rounded-circle header-profile-user" :src="user.avatar" />
                <span class="text-start ms-xl-2">
                  <span class=" d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{ user.name }}</span>
                  <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{{ user.email }}</span>
                </span>
              </span>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <h6 class="dropdown-header">Seja bem-vindo {{user.name}}!</h6>
              <!-- <router-link class="dropdown-item" :to="{ name: 'profile' }">
                <i class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Perfil</span>
              </router-link> -->
              <router-link class="dropdown-item" :to="{ name: 'chats' }">
                <i class=" mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Mensagens</span>
              </router-link>
              <router-link class="dropdown-item" :to="{ name: 'task-list' }">
                <i class="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Tarefas</span>
              </router-link>
              <!-- <router-link class="dropdown-item" to="/pages/faqs">
                <i class="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Ajuda</span>
              </router-link> -->
              <div class="dropdown-divider"></div>
              <router-link v-if="this.user.profile === 'master'" class="dropdown-item" :to="{ name: 'crm-pipelines' }">
                <!-- <span class="badge bg-soft-success text-success mt-1 float-end">Novo</span> -->
                <i class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Configurações</span>
              </router-link>
              <router-link v-if="this.user.profile === 'master'" class="dropdown-item" :to="{ name: 'users' }">
                <i class="ri-user-add-line text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Usuários</span>
              </router-link>
              <a class="dropdown-item" href="#" @click.prevent="logout">
                <i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle" data-key="t-logout">Sair</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
